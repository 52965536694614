"use client";

import {
  Box,
  Flex,
  Heading,
  Text,
  Stack,
  Container,
  Avatar,
  useColorModeValue,
} from "@chakra-ui/react";

const Testimonial = (props) => {
  const { children } = props;

  return <Box>{children}</Box>;
};

const TestimonialContent = (props) => {
  const { children } = props;

  return (
    <Stack
      bg={useColorModeValue("white", "gray.800")}
      boxShadow={"lg"}
      p={8}
      rounded={"xl"}
      align={"center"}
      pos={"relative"}
      _after={{
        content: `""`,
        w: 0,
        h: 0,
        borderLeft: "solid transparent",
        borderLeftWidth: 16,
        borderRight: "solid transparent",
        borderRightWidth: 16,
        borderTop: "solid",
        borderTopWidth: 16,
        borderTopColor: useColorModeValue("white", "gray.800"),
        pos: "absolute",
        bottom: "-16px",
        left: "50%",
        transform: "translateX(-50%)",
      }}
    >
      {children}
    </Stack>
  );
};

const TestimonialText = (props) => {
  const { children } = props;

  return (
    <Text
      textAlign={"center"}
      color={useColorModeValue("gray.600", "gray.400")}
      fontSize={"sm"}
    >
      {children}
    </Text>
  );
};

const TestimonialAvatar = ({ src, name, title }) => {
  return (
    <Flex align={"center"} mt={8} direction={"column"}>
      <Avatar
        src={src}
        mb={4}
        boxSize={{ base: "80px", md: "100px", lg: "120px" }} // Increased size for avatar
      />
      <Stack spacing={-1} align={"center"}>
        <Text fontWeight={600}>{name}</Text>
        <Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
          {title}
        </Text>
      </Stack>
    </Flex>
  );
};

export default function About() {
  return (
    <Box bg={"#f7f2ed"}>
      <Container maxW={"7xl"} py={16} as={Stack} spacing={12}>
        <Stack spacing={0} align={"center"}>
          <Heading size={"lg"}>
            THE TEAM… OR AS WE LIKE TO CALL OURSELVES, THE HORIZONERS
          </Heading>
        </Stack>
        <Stack
          direction={{ base: "column", md: "row" }}
          spacing={{ base: 10, md: 4, lg: 10 }}
        >
          {/* Testimonial 1 */}
          <Testimonial>
            <TestimonialContent>
              <TestimonialText>
                Anthony isn't your typical tour organizer. A seasoned military
                veteran having been core of India's nuclear submarine design
                team, he brings a unique perspective and leadership style to
                Lost Horizon Pvt. Ltd., a premier motorcycle touring company.
                After 14 years of service, Anthony traded his combat boots for
                riding leathers, translating his tactical expertise into
                crafting unforgettable adventures on two wheels. Anthony's time
                in the military honed his ability to navigate challenging
                terrain, prioritize safety, and foster camaraderie. These skills
                seamlessly translate to leading motorcycle tours, ensuring
                riders experience the thrill of the open road with confidence.
                His passion for exploration and his deep understanding of
                veteran needs make Lost Horizon Pvt Ltd a haven for those
                seeking a unique blend of adventure and camaraderie.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={"https://v5imagecollection.s3.us-east-1.amazonaws.com/Anthony.png"}
              name={"Anthony"}
              title={"Director & CEO, Lost Horizon Pvt. Ltd."}
            />
          </Testimonial>

          {/* Testimonial 2 */}
          <Testimonial>
            <TestimonialContent>
              <TestimonialText>
                Saugat isn't just the Director of Lost Horizon Pvt. Ltd., he's
                its heart and soul. A motorcycle enthusiast with a deep love for
                the open road, his passion for exploration fuels the company's
                mission: crafting unforgettable motorcycle adventures. Saugat's
                passion goes beyond the boardroom. He has a wealth of experience
                guiding tours across diverse terrains, ensuring riders
                experience the thrill of the journey while prioritizing safety.
                His knowledge of hidden gems and breathtaking routes allows Lost
                Horizon to curate unique itineraries that take riders far beyond
                the tourist trail.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={"https://v5imagecollection.s3.amazonaws.com/saugat.png"}
              name={"Saugat"}
              title={"Director & COO, Lost Horizon Pvt. Ltd."}
            />
          </Testimonial>

          {/* Testimonial 3 */}
          <Testimonial>
            <TestimonialContent>
              <TestimonialText>
                Having completed her Bachelors in Social Welfare, Amanat worked
                along with UNICEF in a joint project for development in child
                education in the remote locations of Leh, Ladakh. The aim was
                training teachers to improve the quality of education in these
                remote locations. The project involved training, providing
                sustainable infrastructure, setting up digital labs, libraries
                and finally geo mapping 104 schools for monitoring their
                progress. After her Masters, she joined a well-known café chain
                and was instrumental in setting up branches across few states
                and successfully looking after their Operations.
              </TestimonialText>
            </TestimonialContent>
            <TestimonialAvatar
              src={"https://v5imagecollection.s3.us-east-1.amazonaws.com/Amanat.jpg"}
              name={"Amanat"}
              title={"Head (Sales & Marketing), Lost Horizon Pvt. Ltd."}
            />
          </Testimonial>
        </Stack>
      </Container>
    </Box>
  );
}
