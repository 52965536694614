"use client";

import {
  Box,
  chakra,
  Container,
  Stack,
  Text,
  useColorModeValue,
  VisuallyHidden,
} from "@chakra-ui/react";
import {
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      target="_blank"  // Add this line
      rel="noopener noreferrer"  // Recommended for security reasons
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
      }}
    >
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

export default function Footer() {
  return (
    <Box
      borderTopWidth={1}
      borderStyle={"solid"}
      pt={8}
      bg={"#EAEFF2"}
      color={useColorModeValue("gray.700", "gray.200")}
    >
      <Container
        as={Stack}
        maxW={"6xl"}
        spacing={4}
        justify={"center"}
        align={"center"}
      >
        <Box w={"100%"}>
          <Text fontWeight={900} fontFamily={'"Orbitron", sans-serif'}>
            LOST HORIZON PRIVATE LIMITED
          </Text>
        </Box>
        <Stack direction={"row"} spacing={6}></Stack>
      </Container>

      <Box p={4}>
        <Container
          as={Stack}
          maxW={"6xl"}
          py={4}
          direction={"column-reverse"}
          spacing={4}
          justify={{ base: "center", md: "space-between" }}
          align={{ base: "center", md: "center" }}
        >
          <Text>© 2024 Copyright Lost Horizon. All rights reserved</Text>
          <Stack direction={"row"} spacing={6}>
            <SocialButton
              label={"Twitter"}
              href={"https://www.x.com/losthorizoner"}
            >
              <FaTwitter />
            </SocialButton>
            <SocialButton
              label={"YouTube"}
              href={"https://youtube.com/@losthorizoners?si=daDE8q0tRqzreS4w"}
            >
              <FaYoutube />
            </SocialButton>
            <SocialButton
              label={"Instagram"}
              href={"https://www.instagram.com/losthorizoners/"}
            >
              <FaInstagram />
            </SocialButton>
            <SocialButton
              label={"LinkedIn"}
              href={"https://www.linkedin.com/in/lost-horizon-989488314"}
            >
              <FaLinkedinIn />
            </SocialButton>
          </Stack>
        </Container>
      </Box>
    </Box>
  );
}
