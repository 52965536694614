"use client";

import {
  Flex,
  Box,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  Textarea,
  Divider,
} from "@chakra-ui/react";
import { MdOutlineEmail } from "react-icons/md";
import { BsPerson } from "react-icons/bs";
import { BsPhone } from "react-icons/bs";
import ExploreBtn from "../components/ExploreBtn";
import { useState } from "react";
import ChatUs from "../components/ChatUs";
import sendMail from "../components/MailFunc";
import { useToast } from "@chakra-ui/react";
import { FaCheckCircle } from "react-icons/fa";

export default function ContactPage() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showMailSent, setShowMailSent] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let result = await sendMail(name, email, phone, message, setLoading);
    if (result.success) {
      setShowMailSent(true);
      setTimeout(() => {
        setShowMailSent(false);
      }, 2000);
    } else {
      toast({
        title: "Error",
        description: "Failed to send the mail. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-center",
      });
    }

    setEmail("");
    setName("");
    setMessage("");
    setPhone("");
  };

  return (
    <Box
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      minH={"70vh"}
      p={"40px"}
      position={"relative"}
    >
      <Flex
        w={"fit-content"}
        direction={{ base: "column", md: "row" }}
        gap={{ base: "20px", md: "100px" }}
        justify={"center"}
        align={"center"}
      >
        <Box w={"100%"}>
          <Box textAlign={{ base: "center", md: "start" }}>
            <Text
              mb={"48px"}
              fontSize={{ base: "18px", md: "22px" }}
              fontWeight={600}
            >
              LOST HORIZON PRIVATE LIMITED
            </Text>
            <Text fontWeight={600} mt={4}>
              Meet Us:
            </Text>
            <Text># A 3/5, D.T. Estates, Calangute, Goa 403516</Text>
            <Text fontWeight={600} mt={4}>
              Call Us:
            </Text>
            <Text>Amanat (Head Sales & Marketing)</Text>
            <Text>+91 810 810 3595</Text>
            <Text fontWeight={600} mt={4}>
              Mail Us:
            </Text>
            <Text>info@losthorizoners.com</Text>
          </Box>
        </Box>
        <Divider orientation="vertical" />
        <Box
          minWidth={"400px"}
          mt={4}
          boxShadow={
            "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px"
          }
          borderRadius={"15px"}
        >
          <Box
            w={"100%"}
            bg={{ base: "transparent", md: "white" }}
            borderRadius="lg"
            fontFamily={`"Poppins", sans-serif`}
          >
            <Box px={8} py={6} color="#0B0E3F">
              <Text
                fontFamily={'"Orbitron", sans-serif'}
                fontWeight={600}
                py={2}
                fontSize={"18px"}
              >
                Fill the form{" "}
              </Text>
              <VStack spacing={5} as="form" onSubmit={handleSubmit}>
                {/* Name Input */}
                <FormControl id="name" isRequired>
                  <FormLabel>Name</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <BsPerson color="gray.800" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      size="md"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>

                {/* Email Input */}
                <FormControl id="email" isRequired>
                  <FormLabel>Email</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <MdOutlineEmail color="gray.800" />
                    </InputLeftElement>
                    <Input
                      type="email"
                      size="md"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl id="email">
                  <FormLabel>Phone</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <BsPhone color="gray.800" />
                    </InputLeftElement>
                    <Input
                      type="number"
                      size="md"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>

                {/* Message Input */}
                <FormControl id="message" isRequired>
                  <FormLabel>Message</FormLabel>
                  <Textarea
                    borderColor="gray.300"
                    _hover={{
                      borderRadius: "gray.300",
                    }}
                    placeholder="Message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormControl>

                {/* Submit Button */}
                <FormControl id="submit" float="right">
                  <ExploreBtn text={"Submit"} type="submit" loading={loading} />
                </FormControl>
                <Flex
                  display={showMailSent ? "flex" : "none"}
                  color="green"
                  p={2}
                  fontFamily={"'Poppins', sans-serif"}
                  justify={"center"}
                  align={"center"}
                  gap={2}
                >
                  <FaCheckCircle />
                  <Text>Mail sent successfully!</Text>
                </Flex>
              </VStack>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Box>
        <ChatUs />
      </Box>
    </Box>
  );
}
