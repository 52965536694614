import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  Stack,
  Image,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { Link as RouterLink, useNavigate, useLocation } from "react-router-dom";
import styles from "../styles/home.module.css";

export default function Navbar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  const handleClick = () => {
    onClose();
  };

  const handleNavigate = (path) => {
    navigate(path);
    onClose();
  };

  // Helper function to check if the path is active
  const isActive = (path) => location.pathname === path;

  return (
    <>
      <Box
        w="100%"
        top="0"
        zIndex={1000}
        bg={"#EAEFF2"}
        px={4}
        py={3}
        position="sticky"
        fontFamily={'"Orbitron", sans-serif'}
        fontWeight={600}
      >
        <Flex h={16} alignItems={"center"} justifyContent={"space-between"}>
          <HStack
            spacing={8}
            alignItems={"center"}
            justify={"space-between"}
            w="100%"
          >
            <Box
              cursor={"pointer"}
              w={"200px"}
              onClick={() => handleNavigate("/")}
            >
              <Image
                width={"100%"}
                h={"100%"}
                src="https://v5imagecollection.s3.amazonaws.com/LH_LOGO_Trans.png"
                alt="logo"
              />
            </Box>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", lg: "flex" }} // Only show links for large devices
            >
              <Flex
                fontFamily={'"Orbitron", sans-serif'}
                gap={10}
                cursor={"pointer"}
              >
                <RouterLink
                  to="/"
                  className={isActive("/") ? styles.active : ""}
                  onClick={handleClick}
                >
                  HOME
                </RouterLink>
                <RouterLink
                  to="/tours"
                  className={isActive("/tours") ? styles.active : ""}
                  onClick={handleClick}
                >
                  TOURS
                </RouterLink>
                <RouterLink
                  to="/fleet"
                  className={isActive("/fleet") ? styles.active : ""}
                  onClick={handleClick}
                >
                  FLEET
                </RouterLink>
                <RouterLink
                  to="/about"
                  className={isActive("/about") ? styles.active : ""}
                  onClick={handleClick}
                >
                  ABOUT US
                </RouterLink>
                <RouterLink
                  to="/contact"
                  className={isActive("/contact") ? styles.active : ""}
                  onClick={handleClick}
                >
                  CONTACT US
                </RouterLink>
              </Flex>
            </HStack>
            <Box display={{ base: "none", lg: "block" }}></Box>
          </HStack>
          {/* Show hamburger menu for medium devices and below */}
          <IconButton
            size={"md"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ base: "block", md: "block", lg: "none" }} // Show for base and md, hide for lg
            onClick={isOpen ? onClose : onOpen}
          />
        </Flex>

        {isOpen ? (
          <Box pb={4} display={{ md: "block", lg: "none" }}>
            <Stack as={"nav"} spacing={4}>
              <RouterLink
                to="/"
                className={isActive("/") ? styles.active : ""}
                onClick={handleClick}
              >
                HOME
              </RouterLink>
              <RouterLink
                to="/tours"
                className={isActive("/tours") ? styles.active : ""}
                onClick={handleClick}
              >
                TOURS
              </RouterLink>
              <RouterLink
                to="/fleet"
                className={isActive("/fleet") ? styles.active : ""}
                onClick={handleClick}
              >
                FLEET
              </RouterLink>
              <RouterLink
                to="/about"
                className={isActive("/about") ? styles.active : ""}
                onClick={handleClick}
              >
                ABOUT US
              </RouterLink>
              <RouterLink
                to="/contact"
                className={isActive("/contact") ? styles.active : ""}
                onClick={handleClick}
              >
                CONTACT US
              </RouterLink>
            </Stack>
          </Box>
        ) : null}
      </Box>
    </>
  );
}
