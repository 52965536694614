import React from "react";
import { Box, Spinner } from "@chakra-ui/react";
import styles from "../styles/explorebtn.module.css";

const ExploreBtn = ({ func, text, loading }) => {
  return (
    <button onClick={func} className={styles.btn} disabled={loading}>
      {loading ? (
        <Spinner size="md" color="black" />
      ) : (
        <>
          <div className={styles.original}>{text || "Explore"}</div>
          <div className={styles.letters}>
            {text?.length > 0 ? (
              text
                .split("")
                .map((e, index) => (
                  <span key={index}>{e === " " ? "\u00A0" : e}</span>
                ))
            ) : (
              <Box>
                <span>E</span>
                <span>X</span>
                <span>P</span>
                <span>L</span>
                <span>O</span>
                <span>R</span>
                <span>E</span>
              </Box>
            )}
          </div>
        </>
      )}
    </button>
  );
};

export default ExploreBtn;
