import { Box, Image } from "@chakra-ui/react";
import styles from "../styles/associates.module.css";

const AssociatesSection = () => {
  return (
    <Box p={4}  className={styles.associates} overflow="hidden">
      <Box className={styles.slider}>
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L1-1+BMW.png"
          alt="Company 1"
        />
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L1-2+Triumph.png"
          alt="Company 2"
        />
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L1-3+Royal+Enfield.png"
          alt="Company 3"
        />
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L1-4+KTM.png"
          alt="Company 3"
        />
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L2-1+Throttle+Shrottle.png"
          alt="Company 3"
        />
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L2-2+Barrel+Scope.png"
          alt="Company 3"
        />
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L2-3+Angel+Design.png"
          alt="Company 3"
        />
        <Image
          src="https://v5imagecollection.s3.amazonaws.com/L2-4+Origin.png"
          alt="Company 3"
        />
      </Box>
    </Box>
  );
};

export default AssociatesSection;
