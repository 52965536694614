import React, { useState, useEffect } from "react";
import styles from "../styles/home.module.css";
import {
  Box,
  Text,
  Image,
  Flex,
  SimpleGrid,
  Grid,
  IconButton,
} from "@chakra-ui/react";
import Contact from "../components/Contact";
import BikeCard from "../components/Bike";
import { useNavigate } from "react-router-dom";
import ExploreBtn from "../components/ExploreBtn";
import ChatUs from "../components/ChatUs";
import About from "../components/About";
import AssociatesSection from "../components/AssociatesSection";
import { useToast } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";

const Homepage = () => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [offsetY, setOffsetY] = useState(0);
  const [showImage, setShowImage] = useState(false);

  const handleScroll = () => {
    setOffsetY(window.pageYOffset);
  };

  // Check localStorage to see if the image has been shown
  useEffect(() => {
    setShowImage(true);
  }, []);

  // Function to close the image and set localStorage
  const closeImage = () => {
    setShowImage(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const bikes = [
    {
      name: "BMW G 310GS",
      image: "https://v5imagecollection.s3.amazonaws.com/1+BMW+G+310+GS.png",
    },
    {
      name: "KTM 390 ADVENTURE",
      image:
        "https://v5imagecollection.s3.amazonaws.com/2+KTM+390+Adventure.png",
    },
    {
      name: "RE HIMALAYAN 450",
      image:
        "https://v5imagecollection.s3.amazonaws.com/3+RE+Himalayan+450.png",
    },
    {
      name: "TRIUMPH TIGER XRX 800",
      image:
        "https://v5imagecollection.s3.amazonaws.com/4+Triumph+Tiger+800+XRx.png",
    },
  ];

  return (
    <>
      {showImage && (
        <Box
          position="fixed"
          top="0"
          left="0"
          right="0"
          bottom="0"
          bg="rgba(0, 0, 0, 0.7)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="1000"
        >
          <Image
            src="https://v5imagecollection.s3.us-east-1.amazonaws.com/lhstand.jpg"
            alt="Welcome Image"
            maxW="90%"
            maxH="90%"
            objectFit="contain"
          />
          <IconButton
            aria-label="Close"
            icon={<CloseIcon />}
            onClick={closeImage}
            position="absolute"
            top={4}
            right={4}
            colorScheme="white"
          />
        </Box>
      )}
      <Box className={styles.container}>
        <Box
          id="section1"
          className={`${styles.content} ${loading ? styles.hidden : ""}`}
          position="relative"
          h={{ base: "75vh", md: "fit-content" }}
          width="100%"
          overflow="hidden"
        >
          <Image
            display={{ base: "block", md: "none" }}
            width="100%"
            overflow={"hidden"}
            src={"https://v5imagecollection.s3.amazonaws.com/LH_mobile_1.jpg"}
            alt="losthorizon"
          />
          <Image
            display={{ base: "none", md: "block" }}
            width="100%"
            overflow={"hidden"}
            src={
              "https://v5imagecollection.s3.amazonaws.com/homepage-hd_batcheditor_fotor.jpg"
            }
            alt="losthorizon"
          />
        </Box>

        <Box id="section2" bg={"#f7f7f7"} p={8}>
          <Box>
            <Text className={styles.heading}>Tours</Text>
          </Box>
          <Box
            p={{ base: "0px 10px", md: "20px 40px" }}
            height="fit-content"
            width="100%"
          >
            <Text textAlign="justify">
              India stuns with its contrasting beauty. The snow-capped Himalayas
              cradle vibrant valleys, while golden deserts meet turquoise seas.
              Lush tea plantations, carpet-rolling hills, and ancient temples
              pierce bustling cityscapes. Lost Horizon takes you on a journey to
              experience the diverse beauty of India, all while you're riding
              your favourite motorcycle. Imagine riding through a desolate
              landscape, gorgeous terrain with nothing but nature at its best
              all around you! So come, let's explore what we can offer you...
            </Text>
            <Grid className={styles.toors}>
              <Box>
                <Text
                  fontFamily={'"Bebas Neue", sans-serif'}
                  fontSize={{ base: "22px", md: "28px" }}
                  p={2}
                >
                  Ladakh
                </Text>
                <Box
                  w={"100%"}
                  borderRadius="15px"
                  overflow="hidden"
                  h={{ base: "30vh", md: "35vh" }}
                >
                  <Image
                    width="100%"
                    height="100%"
                    src={
                      "https://i1.wp.com/www.inditrip.in/wp-content/uploads/2018/06/leh-ladakh.png?resize=1024%2C576&ssl=1"
                    }
                  />
                </Box>
              </Box>
              <Box>
                <Text
                  fontFamily={'"Bebas Neue", sans-serif'}
                  fontSize={{ base: "22px", md: "28px" }}
                  p={2}
                >
                  Rajasthan
                </Text>
                <Box
                  borderRadius="15px"
                  overflow="hidden"
                  h={{ base: "30vh", md: "35vh" }}
                >
                  <Image
                    width="100%"
                    height="100%"
                    src="https://img.veenaworld.com/wp-content/uploads/2022/06/Know-About-The-Best-Time-to-Visit-Jaisalmer.jpg"
                  />
                </Box>
              </Box>
              <Box>
                <Text
                  fontFamily={'"Bebas Neue", sans-serif'}
                  fontSize={{ base: "22px", md: "28px" }}
                  p={2}
                >
                  North East
                </Text>
                <Box
                  borderRadius="15px"
                  overflow="hidden"
                  h={{ base: "30vh", md: "35vh" }}
                >
                  <Image
                    width="100%"
                    height="100%"
                    src="https://images.unsplash.com/photo-1720071097270-420313c334b1?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                  />
                </Box>
              </Box>
              <Box>
                <Text
                  fontFamily={'"Bebas Neue", sans-serif'}
                  fontSize={{ base: "22px", md: "28px" }}
                  p={2}
                >
                  Goa
                </Text>
                <Box
                  borderRadius="15px"
                  overflow="hidden"
                  h={{ base: "30vh", md: "35vh" }}
                >
                  <Image
                    width="100%"
                    height="100%"
                    src="https://v5imagecollection.s3.amazonaws.com/Tours+Page+-+Goa.jpg"
                  />
                </Box>
              </Box>
            </Grid>
            <Flex
              onClick={() => navigate("/tours")}
              justifyContent="center"
              p={8}
              mt={4}
            >
              <ExploreBtn text={"explore"} />
            </Flex>
          </Box>
        </Box>

        <Box id="section3" p={8}>
          <Box>
            <Text className={styles.heading}>Fleet</Text>
          </Box>
          <Box
            height="fit-content"
            width="100%"
            p={{ base: "0px 10px", md: "20px 40px" }}
          >
            <Text textAlign="justify">
              Built for the open and trail roads, our touring motorcycles are
              the ultimate road trip companions. Imagine cruising in comfort,
              wind whipping past your helmet, as luggage compartments swallow
              your gear. Our bikes boast brand name, reliability, and oomph
              factor, not to mention superior wind protection, comfy seats, and
              workhorse engines for conquering long distances quite comfortably.
            </Text>
            <Grid
              mt={8}
              gap="30px"
              templateColumns={{
                base: "repeat(1, 1fr)",
                md: "repeat(2, 1fr)",
                lg : "repeat(4, 1fr)"
              }}
            >
              {bikes.map((bike, index) => (
                <BikeCard
                  key={index}
                  name={bike.name}
                  image={bike.image}
                  details={bike.details}
                />
              ))}
            </Grid>
            <Flex
              onClick={() => navigate("/fleet")}
              justifyContent="center"
              p={6}
              mt={8}
            >
              <ExploreBtn text={"explore"} />
            </Flex>
          </Box>
        </Box>

        <Box display={"none"} bg={"#f7f7f7"} id="section4" p={8}>
          <Box mt={4}>
            <Text className={styles.heading}>About Us</Text>
            <About />
          </Box>
        </Box>

        <Box
          padding={"30px"}
          pb={"40px"}
          height={"fit-content"}
          border={"2px solid #EDF2F7"}
        >
          <Box>
            <Text pb={2} className={styles.heading}>
              Associates
            </Text>
          </Box>
          <AssociatesSection />
        </Box>

        <Box id="section5" className={styles.contact}>
          <Box>
            <Text p={6} className={styles.heading}>
              Contact Us
            </Text>
          </Box>
          <Contact />
        </Box>
        <Box>
          <ChatUs />
        </Box>
      </Box>
    </>
  );
};

export default Homepage;
