import React, { useState } from "react";
import styles from "../styles/about.module.css";
import { Box, Flex, Image, Text, Button, Divider } from "@chakra-ui/react";
import ChatUs from "../components/ChatUs";

const AboutPage = () => {
  // State to manage the "Read More" functionality for both sections
  const [showFullText1, setShowFullText1] = useState(false);
  const [showFullText2, setShowFullText2] = useState(false);
  const [showFullText3, setShowFullText3] = useState(false);

  // Helper function to truncate text to 100 words
  const truncateText = (text) => {
    const words = text.split(" ");
    return words.length > 100 ? words.slice(0, 120).join(" ") + "..." : text;
  };

  // Helper function to truncate text to 10 lines
  const truncateLines = (text, showFullText) => {
    return (
      <Box
        height={showFullText ? "auto" : "10ch"}
        overflow={showFullText ? "visible" : "hidden"}
        lineHeight="1.5" // Adjust line height as needed to ensure 10 lines
      >
        {text}
      </Box>
    );
  };

  // Full text for Anthony
  const anthonyText = `
    Anthony isn't your typical tour organizer. A seasoned military veteran 
    having been core of India's nuclear submarine design team, he brings a 
    unique perspective and leadership style to Lost Horizon Pvt. Ltd., a 
    premier motorcycle touring company. After 14 years of service, Anthony 
    traded his combat boots for riding leathers, translating his tactical 
    expertise into crafting unforgettable adventures on two wheels. Anthony's 
    time in the military honed his ability to navigate challenging terrain, 
    prioritize safety, and foster camaraderie. These skills seamlessly translate 
    to leading motorcycle tours, ensuring riders experience the thrill of the 
    open road with confidence. His passion for exploration and his deep 
    understanding of veteran needs make Lost Horizon Pvt Ltd a haven for those 
    seeking a unique blend of adventure and camaraderie. So, if you're looking 
    for a motorcycle tour led by a veteran who prioritizes safety, exploration, 
    and creating lasting memories, look no further than Lost Horizon Pvt Ltd with 
    Anthony at the helm.
  `;

  // Full text for Saugat
  const saugatText = `
    Saugat isn't just the Director of Lost Horizon Pvt. Ltd., he's its heart 
    and soul. A motorcycle enthusiast with a deep love for the open road, his 
    passion for exploration fuels the company's mission crafting unforgettable 
    motorcycle adventures. Anything mechanical running on fuel and wheels, and 
    you can expect Saugat to operate it, repair it, love it. Saugat's passion 
    goes beyond the boardroom and the garage. He has a wealth of experience guiding 
    tours across diverse terrains, ensuring 
    riders experience the thrill of the journey while prioritizing safety. His 
    knowledge of hidden gems and breathtaking routes allows Lost Horizon to 
    curate unique itineraries that take riders far beyond the tourist trail. 
    Under Saugat's direction, Lost Horizon fosters a sense of camaraderie and 
    community. He understands that motorcycle touring is more than just riding; 
    it's about shared experiences and forging lasting connections. His dedication 
    to creating a welcoming environment ensures every rider feels like part of the 
    Lost Horizon family.
  `;

  const amanatText = `Having completed her Bachelors in Social Welfare, Amanat worked along with UNICEF in a joint project for development in child education in the remote locations of Leh, Ladakh. The aim was training teachers to improve the quality of education in these remote locations. The project involved training, providing sustainable infrastructure, setting up digital labs, libraries and finally geo mapping 104 schools for monitoring their progress.
After her Masters, she joined a well-known café chain and was instrumental in setting up branches across few states and successfully looking after their Operations. 
Amanat joined Lost Horizon and heads the Sales & Marketing division. Her dynamic personality along with an ‘eye for detail’ reflects the essence of the company. Her calm demeanor belies her ‘wild’ side which is seen in her hobbies of martial arts and mountaineering. That along with her family’s military background, the hardcore Infantry no less, makes her a vital member of the company along with valuable experience to top it.
`;

  return (
    <Box position={"relative"} className={styles.container}>
      <Flex className={styles.main}>
        <Flex className={styles.aboutDiv} direction="column">
          <Box>
            <Image
              src="https://v5imagecollection.s3.amazonaws.com/About+Us+-+Anthony.jpg"
              alt="anthony-img"
              objectFit="cover"
              objectPosition={"top"}
              width={"100%"}
              h={"100%"}
            />
          </Box>
          <Box>
            <Text
              fontFamily={'"Orbitron", sans-serif'}
              fontSize={{ base: "16px", md: "20px" }}
              fontWeight={800}
              mb={2}
            >
              {"Anthony D'Sa – Director & CEO, Lost Horizon Pvt. Ltd."}
            </Text>
            {truncateLines(anthonyText, showFullText1)}
            <Flex mt={4} justify={"center"}>
              <Button
                justify="center"
                onClick={() => setShowFullText1(!showFullText1)}
                size="sm"
                bg={"#FF7E23"}
              >
                {showFullText1 ? "Show Less" : "Read More"}
              </Button>
            </Flex>
          </Box>
        </Flex>

        {/* Saugat's Section */}
        <Flex className={styles.aboutDiv} direction="column">
          <Box>
            <Image
              src="https://v5imagecollection.s3.us-east-1.amazonaws.com/Anthony.png"
              alt="saugat-img"
              objectFit="cover"
              objectPosition={"center"}
              width={"100%"}
              h={"100%"}
            />
          </Box>
          <Box>
            <Text
              fontFamily={'"Orbitron", sans-serif'}
              fontSize={{ base: "16px", md: "20px" }}
              fontWeight={800}
              mb={2}
            >
              {"Saugat Mahapatra – Director & COO, Lost Horizon Pvt. Ltd."}
            </Text>
            {truncateLines(saugatText, showFullText2)}
            <Flex mt={4} justify={"center"}>
              <Button
                onClick={() => setShowFullText2(!showFullText2)}
                size="sm"
                bg={"#FF7E23"}
              >
                {showFullText2 ? "Show Less" : "Read More"}
              </Button>
            </Flex>
          </Box>
        </Flex>
      </Flex>
      <Divider  pt={8} pb={4} />
      <Box className={styles.lowerDiv}>
        <Box
          className={styles.teamCard}
          position="relative"
          w={{ base: "100%", md: "600px" }}
          h="45vh"
        >
          <Image
            src="https://v5imagecollection.s3.us-east-1.amazonaws.com/Amanat.jpg"
            alt="saugat-img"
            objectFit="cover"
            objectPosition="top"
            width="100%"
            height="100%"
          />
        </Box>
        <Box w={"100%"}>
          <Text
            fontFamily={'"Orbitron", sans-serif'}
            fontSize={{ base: "16px", md: "20px" }}
            fontWeight={800}
            textAlign={"start"}
            mb={2}
          >
            {"Amanat Wirk – Head (Sales & Marketing), Lost Horizon Pvt. Ltd."}
          </Text>
          <Text fontSize={"18px"} textAlign={"justify"}>
            {`Having completed her Bachelors in Social Welfare, Amanat worked
            along with UNICEF in a joint project for development in child
            education in the remote locations of Leh, Ladakh. The aim was
            training teachers to improve the quality of education in these
            remote locations. The project involved training, providing
            sustainable infrastructure, setting up digital labs, libraries and
            finally geo mapping 104 schools for monitoring their progress. After
            her Masters, she joined a well-known café chain and was instrumental
            in setting up branches across few states and successfully looking
            after their Operations. Amanat joined Lost Horizon and heads the
            Sales & Marketing division. Her dynamic personality along with an
            ‘eye for detail’ reflects the essence of the company. Her calm
            demeanor belies her ‘wild’ side which is seen in her hobbies of
            martial arts and mountaineering. That along with her family’s
            military background, the hardcore Infantry no less, makes her a
            vital member of the company along with valuable experience to top
            it.`}
          </Text>
        </Box>
      </Box>
      <Box>
        <ChatUs />
      </Box>
    </Box>
  );
};

export default AboutPage;
