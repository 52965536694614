import React from "react";
import styles from "../styles/explorebike.module.css";
import { Box, Image, Text, Flex } from "@chakra-ui/react";
// import ExploreBtn from "../components/ExploreBtn";
import ContactModal from "../components/ContactModal";

const ExploreBike = () => {
  return (
    <Box
      className={styles.container}
      h={"fit-content"}
      w={{ base: "100%", md: "90%" }}
      p={{ base: 4, md: 6 }}
    >
      {/** Bike 1 */}
      <Flex
        className={styles.main}
        height={{ base: "fit-content", md: "40vh" }}
        flexDirection={{ base: "column", md: "row" }}
        mb={6}
      >
        <Box
          className={styles.imgCont}
          flex={{ base: 1, md: 0.5 }}
          mb={{ base: 4, md: 0 }}
        >
          <Image
            w={"100%"}
            height={"100%"}
            objectFit="contain"
            src="https://v5imagecollection.s3.amazonaws.com/1+BMW+G+310+GS.png"
          />
        </Box>
        <Box className={styles.paraCont} flex={{ base: 1, md: 0.5 }}>
          <Text
            letterSpacing={1}
            fontFamily={'"Bebas Neue", sans-serif'}
            fontSize={{ base: "24px", md: "32px" }} // Smaller font size for mobile
            fontWeight={900}
            mb={2}
          >
            BMW G 310 GS
          </Text>
          <Text textAlign={"justify"} fontSize={{ base: "14px", md: "16px" }}>
            The BMW G 310 GS is a small-displacement adventure bike that offers
            a blend of performance and affordability. Its lightweight design and
            nimble handling make it ideal for both city commuting and weekend
            getaways. Powered by a single-cylinder engine, the G 310 GS delivers
            a smooth and reliable ride. With its rugged styling and off-road
            capabilities, this bike is a great choice for riders seeking a
            versatile and fun-to-ride adventure machine.
          </Text>
        </Box>
      </Flex>

      {/** Bike 2 */}
      <Flex
        className={styles.main}
        height={{ base: "fit-content", md: "40vh" }}
        flexDirection={{ base: "column", md: "row" }}
        mb={6}
      >
        <Box
          className={styles.imgCont}
          flex={{ base: 1, md: 0.5 }}
          mb={{ base: 4, md: 0 }}
        >
          <Image
            w={"100%"}
            height={"100%"}
            objectFit="contain"
            src="https://v5imagecollection.s3.amazonaws.com/2+KTM+390+Adventure.png"
          />
        </Box>
        <Box className={styles.paraCont} flex={{ base: 1, md: 0.5 }}>
          <Text
            letterSpacing={1}
            fontFamily={'"Bebas Neue", sans-serif'}
            fontSize={{ base: "24px", md: "32px" }}
            fontWeight={900}
            mb={2}
          >
            KTM 390 ADVENTURE
          </Text>
          <Text textAlign={"justify"} fontSize={{ base: "14px", md: "16px" }}>
            The KTM 390 Adventure is a versatile adventure bike designed for
            both on-road and off-road riding. Its lightweight frame and powerful
            engine make it easy to maneuver in various terrains. With features
            like advanced electronics, a tall windscreen, comfortable seat, and
            rugged suspension, it's well-suited for long-distance touring and
            off-road exploration.
          </Text>
        </Box>
      </Flex>

      {/** Bike 3 */}
      <Flex
        className={styles.main}
        height={{ base: "fit-content", md: "40vh" }}
        flexDirection={{ base: "column", md: "row" }}
        mb={6}
      >
        <Box
          className={styles.imgCont}
          flex={{ base: 1, md: 0.5 }}
          mb={{ base: 4, md: 0 }}
        >
          <Image
            w={"100%"}
            height={"100%"}
            objectFit="contain"
            src="https://v5imagecollection.s3.amazonaws.com/3+RE+Himalayan+450.png"
          />
        </Box>
        <Box className={styles.paraCont} flex={{ base: 1, md: 0.5 }}>
          <Text
            letterSpacing={1}
            fontFamily={'"Bebas Neue", sans-serif'}
            fontSize={{ base: "24px", md: "32px" }}
            fontWeight={900}
            mb={2}
          >
            Royal Enfield Himalayan 450
          </Text>
          <Text textAlign={"justify"} fontSize={{ base: "14px", md: "16px" }}>
            The Royal Enfield Himalayan 450 is a rugged adventure motorcycle
            designed to conquer challenging terrains. Its powerful 450cc engine
            delivers ample torque for off-road exploration. The Himalayan 450
            features a comfortable riding position, long-travel suspension, and
            dual-channel ABS for safety. With its classic design and modern
            capabilities, it's a versatile choice for riders seeking adventure.
          </Text>
        </Box>
      </Flex>

      {/** Bike 4 */}
      <Flex
        className={styles.main}
        height={{ base: "fit-content", md: "40vh" }}
        flexDirection={{ base: "column", md: "row" }}
        mb={6}
      >
        <Box
          className={styles.imgCont}
          flex={{ base: 1, md: 0.5 }}
          mb={{ base: 4, md: 0 }}
        >
          <Image
            w={"100%"}
            height={"100%"}
            objectFit="contain"
            src="https://v5imagecollection.s3.amazonaws.com/4+Triumph+Tiger+800+XRx.png"
          />
        </Box>
        <Box className={styles.paraCont} flex={{ base: 1, md: 0.5 }}>
          <Text
            letterSpacing={1}
            fontFamily={'"Bebas Neue", sans-serif'}
            fontSize={{ base: "24px", md: "32px" }}
            fontWeight={900}
            mb={2}
          >
            Triumph Tiger 800 XRx
          </Text>
          <Text textAlign={"justify"} fontSize={{ base: "14px", md: "16px" }}>
            The Triumph Tiger 800 XRx is a mid-sized adventure bike designed to
            tackle both paved roads and off-road trails. Its powerful 800cc
            triple-cylinder engine delivers a thrilling riding experience, while
            its adjustable suspension and rugged chassis make it well-suited for
            various terrains. The XRx comes equipped with features like heated
            grips, cruise control, and a robust electronics package which offers
            comfort and convenience for long-distance journeys.
          </Text>
        </Box>
      </Flex>

      {/** Call to action section */}
      <Box textAlign={"center"} fontWeight={400} p={{ base: 4, md: 6 }}>
        <Text mt={4} mb={4} fontSize={{ base: "14px", md: "16px" }}>
          Eager to mount one of these mechanical wonders while you ride an
          incredible journey of your life?
        </Text>
        <Text mt={4} mb={4} fontSize={{ base: "14px", md: "16px" }}>
          Well, fill out our Enquiry Form and{" "}
          <span
            style={{
              fontWeight: 600,
              fontSize: "18px",
              marginLeft: "5px",
            }}
          >
            Team Horizoners
          </span>{" "}
          will get back to you shortly!
        </Text>
      </Box>

      {/** Enquiry Button */}
      <Box textAlign="center" mb={6}>
        <ContactModal />
      </Box>
    </Box>
  );
};

export default ExploreBike;
