import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
  FormLabel,
  Box,
  Textarea,
  useToast,
  Flex,
  Text,
} from "@chakra-ui/react";
import ExploreBtn from "./ExploreBtn";
import { MdOutlineEmail } from "react-icons/md";
import { BsPerson, BsPhone } from "react-icons/bs";
import sendMail from "./MailFunc";
import { FaCheckCircle } from "react-icons/fa";

const ContactModal = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [showMailSent, setShowMailSent] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Format the message for WhatsApp
    let result = await sendMail(name, email, phone, message, setLoading);
    if (result.success) {
      setShowMailSent(true);
      setTimeout(() => {
        setShowMailSent(false);
        onClose();
      }, 2000);
    } else {
      toast({
        title: "Error",
        description: "Failed to send the mail. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-center",
      });
      onClose();
    }
    setEmail("");
    setName("");
    setMessage("");
    setPhone("");
  };

  return (
    <>
      {/* <Button onClick={onOpen}>Trigger modal</Button> */}
      <ExploreBtn func={onOpen} text="Enquire Now" />

      <Modal onClose={onClose} isOpen={isOpen} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Enquiry Form</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box px={4} py={3} color="#0B0E3F">
              <VStack spacing={5} as="form" onSubmit={handleSubmit}>
                {/* Name Input */}
                <FormControl id="name" isRequired>
                  <FormLabel>Your Name</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <BsPerson color="gray.800" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      size="md"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>

                {/* Email Input */}
                <FormControl id="email" isRequired>
                  <FormLabel>Mail</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <MdOutlineEmail color="gray.800" />
                    </InputLeftElement>
                    <Input
                      type="email"
                      size="md"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl id="number">
                  <FormLabel>Phone</FormLabel>
                  <InputGroup borderColor="#E0E1E7">
                    <InputLeftElement pointerEvents="none">
                      <BsPhone color="gray.800" />
                    </InputLeftElement>
                    <Input
                      type="number"
                      size="md"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                    />
                  </InputGroup>
                </FormControl>

                {/* Message Input */}
                <FormControl id="message" isRequired>
                  <FormLabel>Message</FormLabel>
                  <Textarea
                    borderColor="gray.300"
                    _hover={{
                      borderRadius: "gray.300",
                    }}
                    placeholder="MESSAGE"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </FormControl>

                {/* Submit Button */}
                <FormControl id="submit" float="right">
                  <ExploreBtn text={"Submit"} type="submit" loading={loading} />
                </FormControl>
                <Flex
                  display={showMailSent ? "flex" : "none"}
                  color="green"
                  p={2}
                  fontFamily={"'Poppins', sans-serif"}
                  justify={"center"}
                  align={"center"}
                  gap={2}
                >
                  <FaCheckCircle />
                  <Text>Mail sent successfully!</Text>
                </Flex>
              </VStack>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default ContactModal;
