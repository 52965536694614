import React from "react";
import { Route, Routes } from "react-router-dom";
import Homepage from "./Homepage";
import ComingSoon from "../components/ComingSoon";
import Explore from "./Explore";
import ExploreBike from "./ExploreBike";
import AboutPage from "./AboutPage";
import ContactPage from "./ContactPage";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Homepage />} />
      {/* <Route path="/fleet" element={<Fleet />} /> */}
      <Route path="*" element={<ComingSoon />} />
      <Route path="/tours" element={<Explore />} />
      <Route path="/fleet" element={<ExploreBike />} />
      <Route path="/about" element={<AboutPage />} />
      <Route path="/contact" element={<ContactPage />} />
    </Routes>
  );
};

export default AllRoutes;
