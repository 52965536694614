import React, { useState } from "react";
import {
  Box,
  IconButton,
  Input,
  Textarea,
  Button,
  Image,
  Spinner,
  useToast,
  Flex,
  Text,
} from "@chakra-ui/react";
import { IoMailOpenOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import { SiWhatsapp } from "react-icons/si";
import { FiX } from "react-icons/fi";
import sendMail from "./MailFunc";
import { FaCheckCircle } from "react-icons/fa";

const ChatUs = () => {
  const [isIconsOpen, setIsIconsOpen] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState();
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const [showMailSent, setShowMailSent] = useState(false);
  const [showMailError, setShowMailError] = useState(false);

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Format the message for WhatsApp

    let result = await sendMail(name, email, phone, message, setLoading);
    if (result.success) {
      setShowMailSent(true);
      setTimeout(() => {
        setShowMailSent(false);
        setShowForm(false);
      }, 2000);
    } else {
      toast({
        title: "Error",
        description: "Failed to send the mail. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "bottom-center",
      });
    }
    setEmail("");
    setName("");
    setMessage("");
    setPhone("");
  };

  const toggleIcons = () => {
    setIsIconsOpen(!isIconsOpen);
    setShowForm(false);
  };

  const openWhatsApp = () => {
    window.open(
      "https://wa.me/+918108103595?text=Hello, I want to know more about lost Horizon.",
      "_blank"
    );
  };

  return (
    <Box position="fixed" bottom="20px" right="20px">
      {/* Chat Button */}
      <IconButton
        w={"60px"}
        h={"60px"}
        bg="#ff7e23"
        _hover={{ background: "#ff7e23" }}
        icon={
          isIconsOpen || showForm ? (
            <FiX color="white" size={"28px"} />
          ) : (
            <Image
              w={"50%"}
              src="https://v5imagecollection.s3.amazonaws.com/Logos+(1).png"
            />
          )
        }
        aria-label="Chat with us"
        borderRadius="50%"
        size="lg"
        onClick={toggleIcons}
        zIndex="2"
      />

      {/* WhatsApp and Mail icons */}
      {isIconsOpen && !showForm && (
        <Box position="absolute" bottom="80px" right="0">
          <IconButton
            w={"60px"}
            h={"60px"}
            borderRadius={"50%"}
            as={motion.div}
            icon={<SiWhatsapp size={"28px"} />}
            aria-label="WhatsApp"
            size="lg"
            colorScheme="green"
            onClick={openWhatsApp}
            mb={3}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            cursor={"pointer"}
          />
          <IconButton
            w={"60px"}
            h={"60px"}
            borderRadius={"50%"}
            as={motion.div}
            icon={<IoMailOpenOutline size={"28px"} />}
            aria-label="Mail"
            size="lg"
            color={"white"}
            bg="#ff7e23"
            _hover={{ bg: "ff7e23" }}
            cursor={"pointer"}
            onClick={() => {
              setShowForm(true);
              setIsIconsOpen(false);
            }}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
          />
        </Box>
      )}

      {/* Floating Form with Transition */}
      {showForm && (
        <Box
          as={motion.div}
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.9 }}
          transition={{ duration: 0.3 }}
          position="absolute"
          bottom="80px"
          right="0"
          p={4}
          bg="white"
          boxShadow="lg"
          borderRadius="md"
          width="300px"
          _before={{
            content: '""',
            position: "absolute",
            bottom: "-10px",
            right: "20px",
            width: "0",
            height: "0",
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid white",
          }}
        >
          <form onSubmit={handleSubmit}>
            <Box
              fontSize={"22px"}
              fontWeight={600}
              p={4}
              fontFamily={'"Orbitron", sans-serif'}
            >
              Contact Us
            </Box>
            <Input
              placeholder="Name"
              mb={3}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <Input
              type="email"
              placeholder="Email"
              mb={3}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <Input
              type="number"
              placeholder="Phone"
              mb={3}
              onChange={(e) => setPhone(e.target.value)}
            />
            <Textarea
              placeholder="Message"
              mb={3}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <Button bg="#ff7e23" color={"white"} width="full" type="submit">
              {loading ? <Spinner /> : "Send Message"}
            </Button>
          </form>
          <Flex
            display={showMailSent ? "flex" : "none"}
            color="green"
            p={2}
            fontFamily={"'Poppins', sans-serif"}
            justify={"center"}
            align={"center"}
            gap={2}
          >
            <FaCheckCircle />
            <Text>Mail sent successfully!</Text>
          </Flex>
        </Box>
      )}
    </Box>
  );
};

export default ChatUs;
