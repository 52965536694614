import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const ComingSoon = () => {
  return (
    <Flex align={"center"} justify={"center"} minH={"70vh"}>
      <Text fontSize={"36px"} fontFamily={'"Bebas Neue", sans-serif'}>
        Coming Soon!
      </Text>
    </Flex>
  );
};

export default ComingSoon;
