import React, { useState } from "react";
import styles from "../styles/explore.module.css";
import { Box, Image, Text, Flex, Button } from "@chakra-ui/react";
// import ExploreBtn from "../components/ExploreBtn";
import ContactModal from "../components/ContactModal";
import ChatUs from "../components/ChatUs";

const Explore = () => {
  const [isExpanded, setIsExpanded] = useState([false, false, false, false]);

  const formatTextWithColon = (text) => {
    return text.split("\n").map((paragraph, i) => {
      return paragraph.split(/(".*?")/).map((part, index) => {
        const isQuote = part.startsWith('"') && part.endsWith('"');
        return (
          <Text
            mt={2}
            fontWeight={isQuote ? 400 : 600} // Set font weight to 400 for quoted text
            key={`${i}-${index}`}
            display="block"
            textAlign={isQuote ? "justify" : "left"}
          >
            {isQuote ? part.slice(1, -1) : part}{" "}
            {/* Remove the quotes for display */}
          </Text>
        );
      });
    });
  };

  const sections = [
    {
      title: "LADAKH",
      text: `"Ladakh, nestled in the northernmost region of India, offer a breathtaking blend of rugged landscapes, ancient monasteries, and vibrant culture. "
    
      Here's a glimpse into this enchanting region -

Landscapes: "Ladakh's stark beauty is defined by its high-altitude deserts, snow-capped mountains, and crystal-clear lakes. The Pangong Lake, a stunning expanse of turquoise water, is a must-visit. The Nubra Valley, with its sand dunes and Bactrian camels, offers a unique desert experience."

Monasteries: "Ladakh is home to numerous ancient monasteries, each with its own unique architecture and history. The Hemis Monastery, one of the largest in Ladakh, is famous for its annual festival. Other notable monasteries include Thiksey, Shey, and Alchi."

Culture: "Ladakh's culture is a fascinating blend of Tibetan, Indian, and Persian influences. The people are warm and hospitable, and their traditional dress and customs are a sight to behold. The Ladakhi cuisine, featuring hearty dishes like momos and thukpa, is a delicious way to experience the local flavors."

Best Time to Visit: "The best time to visit Ladakh is from June to September when the weather is pleasant and the roads are accessible. However, the region can experience unpredictable weather, so it's advisable to check the forecast before planning your trip. Ladakh offer an unforgettable experience for adventure seekers, nature lovers, and those seeking a cultural adventure. With its stunning landscapes, ancient monasteries, and vibrant culture, this region is a true gem in the Himalayas."
`,
      imgSrc:
        "https://i1.wp.com/www.inditrip.in/wp-content/uploads/2018/06/leh-ladakh.png?resize=1024%2C576&ssl=1",
    },
    {
      title: "Rajasthan",
      text: `"Rajasthan, the land of royals, is a captivating state in India renowned for its majestic forts, vibrant culture, and stunning landscapes. With its rich history and diverse attractions, it offers an unforgettable experience for travelers of all ages." 
    Majestic Forts: "One of the highlights of Rajasthan is its majestic forts, which stand as testaments to the region's regal past. The Amber Fort, a UNESCO World Heritage Site, is a must-visit with its intricate carvings, beautiful courtyards, and panoramic views of Jaipur. The Mehrangarh Fort in Jodhpur, another UNESCO World Heritage Site, is equally impressive with its imposing structure and fascinating history."
    Cultural Immersion: "Beyond its forts, Rajasthan boasts a vibrant culture that is reflected in its colorful festivals, traditional dances, and delicious cuisine. The Pushkar Camel Fair, one of the world's largest livestock fairs, is a spectacular event with thousands of camels, horses, and cattle on display. The vibrant colors of the Holi festival, the festival of colors, create a joyful atmosphere throughout the state." 
    Adventure Seekers Paradise: "Rajasthan's diverse landscapes, ranging from the Thar Desert to the Aravalli Hills, offer a variety of outdoor activities. Exploring the sand dunes of the Thar Desert on a camel safari is a thrilling adventure, while hiking through the Aravalli Hills provides a peaceful escape. The serene lakes of Udaipur, such as Lake Pichola and Lake Fateh Sagar, offer stunning views and opportunities for boating."
    Unique Experiences: "In addition to its majestic landscapes and cultural heritage, Rajasthan offers unique experiences like witnessing the grandeur of the Pushkar Camel Fair, exploring the ancient streets of Jaipur’s Pink City, and marveling at the stunning architecture of Udaipur’s palaces. Rajasthan's charm lies in its ability to transport you back in time, offering a royal experience that is as adventurous as it is enriching." `,
      imgSrc:
        "https://v5imagecollection.s3.amazonaws.com/Tours+Page+-+Rajasthan.jpg",
    },
    {
      title: "Goa",
      text: `"The Western Ghats, a UNESCO World Heritage Site, offer a stunning backdrop for a Goa tour. These majestic mountain ranges, stretching along the western coast of India, are a paradise for nature lovers and adventure seekers."

Here are some highlights of exploring the Western Ghats in Goa -

Dudhsagar Falls: "One of India's tallest waterfalls, Dudhsagar Falls is a breathtaking sight. Surrounded by lush greenery, it's a popular spot for trekking, swimming, and enjoying a picnic."
Cotigao Wildlife Sanctuary: "This sanctuary is home to a diverse range of flora and fauna, including elephants, leopards, and various bird species. Take a guided trek or safari to experience the beauty of the Western Ghats."
Trekking and Hiking: "The Western Ghats offer numerous trekking trails suitable for all levels. Explore the dense forests, conquer challenging peaks, and enjoy panoramic views of the surrounding landscapes."
Waterfalls: "Besides Dudhsagar Falls, there are many other beautiful waterfalls in the Western Ghats, such as Keri Falls, Tambdi Surla Falls, and Netravali Falls. Take a refreshing dip or simply admire their natural beauty."
Birdwatching: "The Western Ghats are a birdwatcher's paradise. Keep an eye out for endemic species like the Malabar Pied Hornbill, Malabar Whistling Thrush, and Nilgiri Woodpigeon."
Adventure Activities: "Engage in thrilling activities like whitewater rafting, kayaking, and zip-lining in the Western Ghats. Experience the adrenaline rush and create unforgettable memories. A tour of the Western Ghats in Goa is an opportunity to connect with nature, explore hidden gems, and discover the beauty of this UNESCO World Heritage Site. Whether you're seeking adventure, relaxation, or simply a peaceful escape, the Western Ghats have something to offer to everyone.."`,
      imgSrc: "https://v5imagecollection.s3.amazonaws.com/Tours+Page+-+Goa.jpg",
    },
    {
      title: "North-East",
      text: `"North East India, a beautiful and serene region often overlooked by tourists, is a hidden gem for you to discover. Nowhere can you find such a treasure trove of natural beauty, diverse cultures, and rich traditions. With its rugged terrain of snowcapped mountains, breathtaking landscapes, and amazing culture, it is a ‘Utopia’ for adventure motorcycle riders." 
Scenic Beauty: "Nestled between the Himalayas and Myanmar, North East boasts of diversity where you can experience riding through their winding mountain roads of Darjeeling or dense forests of Arunachal Pradesh, each place dotted with picturesque valleys, lush tea plantations and serene lakes. "
Cultural Immersion: "Explore the vibrant cultures of various tribes and ethnic groups. Interact with locals, learn about their traditions, experience their rich and vibrant festivals and indulge in their delicious cuisine, all of which Nagaland is famous for." 
Adventure Seekers Paradise: "Conquer challenging terrains, tackle off-road trails, and experience the thrill of riding through remote regions or Kaziranga National Park, home to the one-horned rhinoceroses.These rides give off ‘I want more’ vibes every single kilometer you travel. "
Unique Experiences: "Witness stunning waterfalls, explore ancient monasteries, and visit wildlife sanctuaries.The Tsomgo Lake and the ancient Rumtek Monastery offer an atmosphere of peace and serenity which you will rarely find anywhere else, not to mention the Majuli Island, where you can enjoy the scenic beauty of the Brahmaputra River. A ride through North East will realign your Chakras for life! "`,
      imgSrc:
        "https://images.unsplash.com/photo-1720071097270-420313c334b1?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
    },
  ];

  return (
    <Box className={styles.container} h={"fit-content"} w={"100%"}>
      {sections.map((section, index) => (
        <Flex
          key={index}
          className={styles.main}
          height={{ base: "fit-content", md: "fit-content" }}
        >
          <Box
            p={2}
            h={{ base: "40vh", md: "60vh" }}
            className={styles.imgCont}
          >
            <Image w={"100%"} height={"100%"} src={section.imgSrc} />
          </Box>
          <Box className={styles.paraCont}>
            <Text
              letterSpacing={1}
              fontFamily={'"Bebas Neue", sans-serif'}
              fontSize={{ base: "22px", md: "32px" }}
              fontWeight={900}
            >
              {section.title}
            </Text>

            <Text textAlign="justify" display={{ base: "block", md: "block" }}>
              {formatTextWithColon(section.text)}
            </Text>
          </Box>
        </Flex>
      ))}

      <Box textAlign={"center"} fontWeight={400} p={{ base: 4, md: 6 }}>
        <Text mt={4} mb={4} fontSize={{ base: "14px", md: "16px" }}>
          You think you are ready for an adventure{" "}
          <span
            style={{
              fontWeight: 600,
              fontSize: "18px",
              marginLeft: "5px",
            }}
          >
            BEYOND ORDINARY
          </span>
          {"?"}
        </Text>
        <Text mt={4} mb={4} fontSize={{ base: "14px", md: "16px" }}>
          Well, fill out our Enquiry Form and{" "}
          <span
            style={{
              fontWeight: 600,
              fontSize: "18px",
              marginLeft: "5px",
            }}
          >
            Team Horizoners
          </span>{" "}
          will get back to you shortly!
        </Text>
      </Box>
      <Box textAlign="center" mb={6}>
        <ContactModal />
      </Box>
    </Box>
  );
};

export default Explore;
