import React from "react";
import { Box, Image, Heading } from "@chakra-ui/react";

const BikeCard = ({ name, image, details }) => {
  return (
    <Box
      w={"100%"}
      overflow="hidden"
      p={4}
      textAlign="center"
      mx="auto"
      borderRadius={"15px"}
      boxShadow="rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px"
      transition={"0.1s"}
      _hover={{
        outline: "2px solid #FF7E23",
      }}
    >
      <Heading
        letterSpacing={1}
        fontFamily={'"Orbitron", sans-serif'}
        fontSize={["14px", "16px", "16px"]}
        isTruncated
        mb={4}
      >
        {name}
      </Heading>
      <Image
        src={
          image ||
          "https://pngfre.com/wp-content/uploads/bike-png-image-pngfre-42-1024x704.png"
        }
        alt={name}
        mb={4}
      />
    </Box>
  );
};

export default BikeCard;
